async function fetchArtists(url) {
  try {
    const artistFetch = await fetch(url);
    const artistJson = await artistFetch.json();
    return artistJson.artist;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export { fetchArtists };
