import React, { useEffect, useRef } from 'react';
import hoverEffect from 'hover-effect';
import { Link } from 'react-router-dom';

function Artist({ artist }) {
  const dist = useRef(null);
  useEffect(() => {
    new hoverEffect({
      parent: dist.current,
      intensity: 0.3,
      image1: artist.top_img,
      image2: artist.bottom_img,
      displacementImage: artist.displacement
    });
  });

  return (
    <Link to={`artist/${artist.url}`}>
      <div className="artist-item">
        <div className="distortion no-opacity" ref={dist} />
        <div className="artist-heading">{artist.name}</div>
      </div>
    </Link >
  )
}

export default Artist