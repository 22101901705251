import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './components/Header/Header';
import App from './App';
import ArtistDetails from './components/ArtistDetails/ArtistDetails';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

ReactDOM.render(
  <React.Fragment>
    <Header />
    {/* <Router basename='/layout'> */}
    <Router>
      <Switch>
        <Route path='/' exact component={App} />
        <Route path='/artist/:name' exact component={ArtistDetails} />
      </Switch>
    </Router>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
