import React from 'react';
import { ReactComponent as Logo } from './gw_logo.svg'
import { ReactComponent as Records } from './records.svg';

function Header() {
  return (
    <header>
      <div className="clip">
        <div className="container">    
          <div className="logo-container">
            <Logo onClick={() => window.history.go(-1)} />
            <Records />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header