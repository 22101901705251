import React, { useEffect, useRef } from 'react';
import Loader from './../Loader/Loader';
import Artist from './../Artist/Artist';

function Main({ artists, doneLoading }) {
  const artistsEl = useRef();
  const loader = useRef();

  const show = (el, loader) => {
    setTimeout(() => {
      loader.classList.add('hide');
      el.classList.add('show');
    }, 1500);
  };

  const renderArtists = (items) => {
    console.log(items);
    if (items.length) {
      return items.map((artist, id, arr) => {
        if (id === arr.length - 1) {
          show(artistsEl.current, loader.current);
        }
        return <Artist key={id} artist={artist} />;
      });
    }
    return null;
  };

  useEffect(() => {
    const artistsElC = artistsEl.current;
    // const loadingSpinner = loader.current;
    return () => {
      //loadingSpinner.classList.remove('hide');
      artistsElC.classList.remove('show');
    };
  });

  useEffect(() => {
    console.log('done LOADING!!!!');
  }, [doneLoading]);

  return (
    <main>
      <div className="description">
        <p>
          <strong>German Wahnsinn Records</strong> ist das Label der
          <strong> German Wahnsinn Recording Studios</strong>. Viele namhafte
          Musiker und Musikerinnen haben sich hier schon die Klinke in die Hand
          gegeben.
          <strong>Zwischen Reeperbahn und Landungsbrücken</strong> sorgen wir
          mit Leidenschaft und viel Platz zum Toben für die Produktion, die
          Veröffentlichung und den Vertrieb von Musik
          <strong> ausgewählter Künstler vor allem aus Hamburg</strong>. Dabei
          sind wir offen für unterschiedliche Genres und außergewöhnliche Ideen.
          Wir nutzen unser Netzwerk in der Musik- und der Werbebranche, um für
          individuelle Ziele gemeinsam den richigen Weg zu finden.
        </p>
      </div>
      <Loader ref={loader} />
      <div className="artists" ref={artistsEl}>
        {artists.length ? renderArtists(artists) : null}
      </div>{' '}
    </main>
  );
}

export default Main;
