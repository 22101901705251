import React from 'react';

function Footer() {
  return (
    <footer>
      <div className='footer'>
        <p>
          <small>Kastanienallee 9, 20359 Hamburg</small>
          <small>Tel: +49 (0)40-32536244</small>
          <small>
            <a href='mailto:wahnsinn@germanwahnsinn.de'>
              WAHNSINN@GERMANWAHNSINN.DE
            </a>
          </small>
        </p>

        <nav>
          <ul className='footer-nav'>
            <li>
              <a
                href='https://germanwahnsinn.de/'
                rel='noopener noreferrer'
                target='_blank'
              >
                German Wahnsinn
              </a>
            </li>
            <li>
              <a
                href='https://germanwahnsinn.de/legal/#imprint'
                rel='noopener noreferrer'
                target='_blank'
              >
                Impressum
              </a>
            </li>
            <li>
              <a
                href='https://germanwahnsinn.de/legal/#privacy'
                rel='noopener noreferrer'
                target='_blank'
              >
                Datenschutz
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;