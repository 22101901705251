import React, { useEffect, useState, useRef } from 'react';
import Footer from './../Footer/Footer';
import {
  FaFacebook,
  FaGlobe,
  FaFlushed,
  FaInstagram,
  FaFire,
} from 'react-icons/fa';

// import { FaFire } from 'react-icons/ri';

function BackButton() {
  return (
    <button className="back-btn" onClick={() => window.history.go(-1)}>
      Zurück
    </button>
  );
}

function ArtistDetails({ match }) {
  const [artist, setArtist] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const imagesEl = useRef(null);
  const bioEl = useRef(null);

  // const handleResize = useCallback(() => {
  //   setSizes(bioEl);
  // }, [bioEl]);

  // const setSizes = () => {
  //   setTimeout(() => {
  //     if (window.innerWidth > 1080) {
  //       const rect = imagesEl.current.getBoundingClientRect();
  //       bioEl.current.style.height = rect.height + 'px';
  //       setIsVisible(true);
  //     } else {
  //       getBoundingClientRect();
  //       bioEl.current.style.height = rect.height + 72 + 'px';
  //       setIsVisible(true);
  //     }
  //   }, 0);
  // };

  const {
    params: { name },
  } = match;

  useEffect(() => {
    const artists = JSON.parse(localStorage.getItem('artist'));
    const [first] = artists.filter((a) => a.url === name);
    setArtist(first);
    setIsVisible(true);
    //setSizes();
    //window.addEventListener('resize', handleResize);
    //return () => window.removeEventListener('resize', handleResize);
  }, [/*handleResize,*/ name]);

  const createIcon = (iconType) => {
    switch (iconType) {
      case 'facebook':
        return <FaFacebook />;
      case 'website':
        return <FaGlobe />;
      case 'instagram':
        return <FaInstagram />;
      case 'linkfire':
        return <FaFire />;
      default:
        return <FaFlushed />;
    }
  };
  const { bio, socials } = artist;

  return (
    <React.Fragment>
      <div className={`artist-details${isVisible ? ' show' : ''}`}>
        <h1>{artist.name}</h1>
        <div className="artist-content top-padding-32">
          <div className="images" ref={imagesEl}>
            {/* <img src={`/layout/${artist.top_img}`} alt={artist.name} /> */}
            {isVisible ? (
              <img
                src={`${window.location.origin}/${artist.detail_img}`}
                alt={artist.name}
              />
            ) : null}
          </div>
          <div className="bio" ref={bioEl}>
            {isVisible
              ? bio.map((p, idx) => (
                  <p key={idx} dangerouslySetInnerHTML={{ __html: p }} />
                ))
              : null}
            {/* <BackButton /> */}
          </div>
        </div>
        <div className="artist-content space-between">
          <BackButton />
          <ul className="socials">
            {isVisible
              ? socials.map((s, idx) => (
                  <li key={idx}>
                    <a target="_blank" rel="noopener noreferrer" href={s.url}>
                      {createIcon(s.type)}
                    </a>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ArtistDetails;
