import React, { useState, useEffect } from 'react';
import { fetchArtists } from './data';
import ImagePreloader from 'image-preloader';

import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';

function App() {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await fetchArtists(process.env.REACT_APP_ARTISTS_LIST);
      // @todo preload images
      const imgs = data.map((img) => img.top_img);
      const preloader = new ImagePreloader();
      // preloader.onProgress = function (info) {
      //   console.log(
      //     'image with source %s is loaded with status %s',
      //     info.value.src,
      //     info.status
      //   );
      // };
      preloader.preload(...imgs).then(function (status) {
        console.log('images loading is all done!', status);
        setArtists(data);
        localStorage.setItem('artist', JSON.stringify(data));
      });
    })();
  }, []);

  return (
    <React.Fragment>
      <Main artists={artists} />
      <Footer />
    </React.Fragment>
  );
}

export default App;
