import React from 'react';

const Loader = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
});

export default Loader;
